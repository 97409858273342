<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">客户信息</span>
      <Button type="primary" class="client_create" @click="create" v-if="aCheck.rightCheck('edit_client_info')">创建新客户
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" ref="search-form" inline>
          <FormItem prop="client_no">
            <Input @on-blur="searchSubmit" v-model="formItem.client_no" placeholder="请填写客户编号" class="w-200"/>
          </FormItem>

          <FormItem prop="client_name">
            <Input @on-blur="searchSubmit" v-model="formItem.client_name" placeholder="请填写客户名称" class="w-200"/>
          </FormItem>

<!--          <FormItem prop="category_id">-->
<!--            <Select @on-change="searchSubmit" v-model="formItem.category_id" class="w-200" placeholder="请选择客户等级" filterable>-->
<!--              <Option v-for="item in category_options" :value="item.id" :key="item.id">{{ item.name }}</Option>-->
<!--            </Select>-->
<!--          </FormItem>-->

          <FormItem >
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="clientInfo_list">
      <Table :columns="columnsInfo" :data="listInfo">
        <template slot-scope="{ row }" slot="client_no">
          <strong class="pointer" @click="viewItem(row)">{{ row.client_no }}<span v-show="row.postfix">-</span>&nbsp;<span>{{row.postfix}}</span></strong>
        </template>

        <template slot-scope="{ row }" slot="type">
          <span v-for="item in type_list" v-if="item.id==row.type">{{ item.name }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">

          <Button type="text" class="fs-12 status-main" size="small" @click="viewItem(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Dropdown v-if="aCheck.rightCheck('edit_client_info')">
            <Button size="small" type="text" class="fs-12">
              更多<Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem >
                <span @click="listEdit(row)" class="f12"><Icon type="ios-create"/>修改</span>
              </DropdownItem>
              <DropdownItem>
                <span @click="listDeleteAlert(row)" class="f12"><Icon type="ios-trash"/>删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <Modal v-model="modal1" :title="model_title" width="800">
      <div id="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="客户编号" prop="client_no">
                <Input v-model="clientFormItem.client_no" disabled placeholder="请填写客户编号(建议英文简称)" style="width: 120px;"></Input>
                <span> - </span>&nbsp;
                <Input v-model="clientFormItem.postfix" placeholder="请填写自定义编号" style="width: 132px;" maxlength="20"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="客户名称" prop="client_name">
                <Input v-model="clientFormItem.client_name" placeholder="请填写客户名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
<!--            <Col span="12">-->
<!--              <FormItem label="客户简称" prop="simple_name">-->
<!--                <Input v-model="clientFormItem.simple_name" placeholder="请填写客户简称"></Input>-->
<!--              </FormItem>-->
<!--            </Col>-->
            <Col span="12">
              <FormItem label="类型" prop="type_info">
                <Select v-model="clientFormItem.type" placeholder="请选择类型">
                  <Option v-for="item in type_list" :value="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
<!--            <Col span="12">-->
<!--              <FormItem label="联系人" prop="contact_person">-->
<!--                <Input v-model="clientFormItem.contact_person" placeholder="请填写联系人"></Input>-->
<!--              </FormItem>-->
<!--            </Col>-->
            <Col span="12">
              <FormItem label="手机号" prop="contact_phone">
                <Input v-model="clientFormItem.contact_phone" placeholder="请填写手机号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="联系地址" prop="address">
                <Input v-model="clientFormItem.address" maxlength="500" placeholder="请填写联系地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注" prop="remark">
                <Input v-model="clientFormItem.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>

          <Divider>开票资料填写</Divider>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="公司抬头" prop="account_name">
                <Input v-model="clientFormItem.account_name" maxlength="200" placeholder="请填写公司抬头"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="税号" prop="tax_number">
                <Input v-model="clientFormItem.tax_number" maxlength="200" placeholder="请填写税号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="公司地址" prop="company_address">
                <Input v-model="clientFormItem.company_address" maxlength="1000" placeholder="请填写公司地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="电话" prop="telephone">
                <Input v-model="clientFormItem.telephone" maxlength="1000" placeholder="请填写电话"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="开户银行" prop="bank_name">
                <Input v-model="clientFormItem.bank_name" maxlength="200" placeholder="请填写开户银行"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="账号" prop="bank_number">
                <Input v-model="clientFormItem.bank_number" maxlength="200" placeholder="请填写账号"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">取消</Button>
        <Button type="primary" :loading="modal_loading" @click="createClient('clientFormItem')">保存</Button>
      </div>

    </Modal>

    <!--查看-->
    <Modal v-model="view.modal" title="查看客户信息" width="800">
      <div class="modalForm">
        <Form :model="view.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="客户编号" prop="client_no">
                <span>{{view.item.client_no}}</span> <span v-show="view.item.postfix">-</span>&nbsp;<span>{{view.item.postfix}}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="客户名称" prop="client_name">
                <span>{{view.item.client_name}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
<!--            <Col span="12">-->
<!--              <FormItem label="客户简称" prop="simple_name">-->
<!--                <span>{{view.item.simple_name}}</span>-->
<!--              </FormItem>-->
<!--            </Col>-->
            <Col span="12">
              <FormItem label="类型" prop="type">
                <span v-for="item in type_list" v-if="item.id==view.item.type">{{ item.name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="手机号" prop="contact_phone">
                <span>{{view.item.contact_phone}}</span>
              </FormItem>
            </Col>
<!--            <Col span="12">-->
<!--              <FormItem label="联系人" prop="contact_person">-->
<!--                <span>{{view.item.contact_person}}</span>-->
<!--              </FormItem>-->
<!--            </Col>-->
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="联系地址" prop="address">
                <span>{{view.item.address}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注" prop="remark">
                <span>{{view.item.remark}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
<!--            <Col span="12">-->
<!--              <FormItem label="客户等级" prop="category_id">-->
<!--                <span v-for="item in category_options" v-if="item.id==view.item.category_id">{{ item.name }}</span>-->
<!--              </FormItem>-->
<!--            </Col>-->

          </Row>


          <Divider>开票资料</Divider>
          <Row :gutter="20">
            <Col span="24">
              <p><span style="text-align: right;width:80px;display: inline-block;margin-right:10px;">公司抬头:</span>{{view.item.account_name}}</p>
              <p><span style="text-align: right;width:80px;display: inline-block;margin-right:10px;">税号:</span>{{view.item.tax_number}}</p>
              <p><span style="text-align: right;width:80px;display: inline-block;margin-right:10px;">公司地址:</span>{{view.item.company_address}}</p>
              <p><span style="text-align: right;width:80px;display: inline-block;margin-right:10px;">电话:</span>{{view.item.telephone}}</p>
              <p><span style="text-align: right;width:80px;display: inline-block;margin-right:10px;">开户银行:</span>{{view.item.bank_name}}</p>
              <p><span style="text-align: right;width:80px;display: inline-block;margin-right:10px;">账号:</span>{{view.item.bank_number}}</p>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closeViewModal()">关闭</Button>
        <Button type="primary" :loading="modal_loading" @click="listEdit(view.item)">修改</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
import * as clientsUtil from '@/utils/client'
import {apiGetClientCategoryAll} from "@/api/setting/clientCategory";
import * as supplier_utils from "@/utils/supplier";

export default {
  name: "ClientInfo",
  data() {
    return {
      category_options: [],
      view: {
        modal: false,
        item: {}
      },
      formItem: {
        client_no: '',
        client_name: '',
        category_id: 0,
      },
      type_list: [
        {
          id:1,
          name: '客户',
        },
        {
          id:3,
          name: '客户与供应商',
        },
      ],
      clientFormItem: {
        id: '',
        type:1,
        postfix: '',
        client_no: '',
        client_name: '',
        // simple_name: '',
        contact_person: '',
        contact_phone: '',
        address: '',
        account_name: '',
        tax_number: '',
        bank_name: '',
        bank_number: '',
        company_address: '',
        category_id: 0,
      },
      clientFormItembak: {},
      model_title: '编辑客户信息',
      modal1: false,
      modal_loading: false,
      columnsInfo: [
        {
          title: '客户编号',
          align: 'center',
          key: 'client_no',
          slot: 'client_no',
        },
        {
          title: '客户全称',
          key: 'client_name',
          align: 'center',
        },
        {
          title: '类型',
          align: 'center',
          key: 'type',
          slot: 'type'
        },
        // {
        //   title: '联系人',
        //   align: 'center',
        //   key: 'contact_person'
        // },
        {
          title: '联系电话',
          align: 'center',
          key: 'contact_phone'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          slot: 'action',
        }
      ],
      listInfo: [],
      ruleInline: {
        client_no: [
          {required: true, message: '请填写客户编号'}
        ],
        client_name: [
          {required: true, message: '请填写客户名称'},
          // {pattern:/^\w{0,200}$/, message: '长度不能超过200'}
        ],
        // simple_name: [
        //   {required: true, message: '请填写客户简称'},
        //   // {pattern:/^\w{0,200}$/, message: '长度不能超过200'}
        // ],
        // contact_person: [
        //   {required: true, message: '请填写联系人'},
        //   // {pattern:/^\w{0,200}$/, message: '长度不能超过200'}
        // ],
        contact_phone: [
          {pattern: /^[0-9]{11}$/, message: '请填写正确手机号格式'}
        ]
      },
      listTotal: 0,
      pageSize: 0,
      // allClientNo: [],
      searchFlag: 0,
    };
  },
  methods: {
    // getClientCategoryAll() {
    //   apiGetClientCategoryAll().then(res => {
    //     if (res.data.err_no == 0) {
    //       this.category_options = res.data.results;
    //     }
    //   })
    // },
    extraOperate() {
      clientsUtil.resetClientsAll();
      supplier_utils.resetSupplierAll();
    },
    handleReset(name) {
      this.clientFormItem = this.clientFormItembak;
      this.modal1 = false;
      this.modal_loading = false;
    },
    create() {
      this.modal_loading = false;
      this.model_title = '创建客户信息';
      this.modal1 = true;
      this.$axios.get('/api/transitunit/getClientId').then((res) => {
            if (res.data.err_no == 0) {
              this.clientFormItem = this.clientFormItembak;
              this.clientFormItem.client_no = res.data.results;
            }
          });
    },
    listEdit(row) {
      this.view.modal = false;
      this.clientFormItem = row;
      this.modal1 = true;
    },
    viewItem(row) {
      this.view.item = row;
      this.view.modal = true;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除客户信息",
        content: '确定删除客户信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo($id) {
      this.$axios.post('/api/transitunit/deleteListInfo', {id: $id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getClientList();
          this.extraOperate();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    closeViewModal() {
      this.view.modal = false;
    },
    createClient(name) {
      this.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (!valid) {
          this.modal_loading = false;
          return;
        }

        this.clientFormItem.client_no = this.clientFormItem.postfix == '' ? this.clientFormItem.client_no : this.clientFormItem.client_no.concat('-', this.clientFormItem.postfix);
        this.$axios.post('/api/transitunit/createClient', this.clientFormItem).then((res) => {
          if (res.data.err_no == 0) {
            this.$Message.success('客户信息保存成功');
            this.$refs[name].resetFields();
            this.modal1 = false;
            this.getClientList();
            this.extraOperate();
          } else {
            this.$Message.error(res.data.err_msg);
          }
          this.modal_loading = false;
        }).catch((error) => {
          this.$Message.error("操作失败！");
          this.modal_loading = false;
        });

      });

    },
    searchSubmit() {
      this.getClientList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getClientList();
    },
    getClientList($page = 1) {
      let param = {
        params: {
          page: $page,
          client_no: this.formItem.client_no,
          client_name: this.formItem.client_name,
          category_id: this.formItem.category_id,
        }
      };
      this.$axios.get('/api/transitunit/getClientList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },
    // getClientAllId() {
    //   this.$axios.get('/api/transitunit/getClientAllId').then((response) => {
    //     if (response.data.err_no == 0) {
    //       this.allClientNo = response.data.results;
    //     }
    //   });
    // },
    changePage(page) {
      this.getClientList(page);
    }
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.clientFormItembak = this.clientFormItem;

    // this.getClientCategoryAll();
    this.getClientList();
    // this.getClientAllId();
  },
};
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>
<style>
#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

#list .ivu-table-wrapper {
  min-height: auto;
}

#list .ivu-table-fixed-body {
  min-height: auto;
}

.clientInfo_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.clientInfo_list .ivu-table-wrapper {
  overflow: visible;
}
</style>
