<template>
  <div class="layout">
    <Layout>
      <Layout>
        <Sider width="200">
          <SettingMenu @func="getMsgFormMenu" :active="title_seclectd" :open="open"></SettingMenu>
        </Sider>
        <Content>
          <OrgInfo v-if="title_seclectd == '1-1'"></OrgInfo>
<!--          <AddressManage v-if="title_seclectd == '1-2'"></AddressManage>-->
          <DepartmentSetting v-if="title_seclectd == '1-3'"></DepartmentSetting>
          <ProjectSetting v-if="title_seclectd == '1-4'"></ProjectSetting>
          <WarehouseSetting v-if="title_seclectd == '2-3'"></WarehouseSetting>
<!--          <HouseAreaSetting v-if="title_seclectd == '2-4'"></HouseAreaSetting>-->
<!--          <HousePositionSetting v-if="title_seclectd == '2-5'"></HousePositionSetting>-->
          <ClientInfo v-if="title_seclectd == '3-6'"></ClientInfo>
          <SupplierInfo v-if="title_seclectd == '3-7'"></SupplierInfo>
          <LogisticsInfo v-if="title_seclectd == '3-8'"></LogisticsInfo>
          <FlowCategorySetting v-if="title_seclectd == '4-9'"></FlowCategorySetting>
          <FileCategorySetting v-if="title_seclectd == '5-10'"></FileCategorySetting>
          <AccountSetting v-if="title_seclectd == '6-11'"></AccountSetting>
          <RoleSetting v-if="title_seclectd == '6-12'"></RoleSetting>
          <GoodsLabelSet v-if="title_seclectd == '5-13'"></GoodsLabelSet>
          <BuyCategory v-if="title_seclectd == '5-14'"></BuyCategory>
          <PictureLib v-if="title_seclectd == '5-15'"></PictureLib>
          <ClientCategory v-if="title_seclectd == '5-16'"></ClientCategory>
          <PayDesc v-if="title_seclectd == '5-17'"></PayDesc>
<!--          <UnitSet v-if="title_seclectd == '5-18'"></UnitSet>-->
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import SettingMenu from '@/components/menu/SettingMenu.vue';
import OrgInfo from '@/views/setting/OrgInfo.vue';
// import AddressManage from '@/views/setting/AddressManage.vue';
import WarehouseSetting from '@/views/setting/WarehouseSetting.vue';
// import HousePositionSetting from '@/views/setting/HousePositionSetting.vue';
// import HouseAreaSetting from '@/views/setting/HouseAreaSetting.vue';
import ClientInfo from '@/views/transitunit/ClientInfo.vue';
import SupplierInfo from '@/views/transitunit/SupplierInfo.vue';
import LogisticsInfo from '@/views/transitunit/LogisticsInfo.vue';
import FlowCategorySetting from '@/views/setting/FlowCategorySetting.vue';
import FileCategorySetting from '@/views/setting/FileCategorySetting.vue';
import GoodsLabelSet from '@/views/setting/GoodsLabelSet.vue';
import AccountSetting from '@/views/setting/AccountSetting.vue';
import RoleSetting from '@/views/setting/RoleSetting.vue';
import DepartmentSetting from '@/views/setting/DepartmentSetting.vue';
import ProjectSetting from '@/views/setting/ProjectSetting.vue';
import BuyCategory from "@/views/setting/BuyCategory";
import ClientCategory from "@/views/setting/ClientCategory.vue";
import PayDesc from "@/views/setting/PayDesc";
import PictureLib from "@/views/setting/PictureLib";
// import UnitSet from "@/views/setting/UnitSet.vue";

export default {
  name: "Setting",
  components: {
    OrgInfo,
    SettingMenu,
    // AddressManage,
    WarehouseSetting,
    // HousePositionSetting,
    // HouseAreaSetting,
    ClientInfo,
    SupplierInfo,
    LogisticsInfo,
    FlowCategorySetting,
    FileCategorySetting,
    AccountSetting,
    RoleSetting,
    DepartmentSetting,
    GoodsLabelSet,
    ProjectSetting,
    BuyCategory,
    ClientCategory,
    PayDesc,
    PictureLib,
    // UnitSet
  },
  data() {
    return {
      title_seclectd: "1-1",
      open: ['1'],
      menuName: "Setting",
      openName: 'SettingOpenName',
    };
  },
  created() {
    this.title_seclectd = localStorage.getItem(this.menuName) ? localStorage.getItem(this.menuName) : '1-1';
    this.open = localStorage.getItem(this.openName) ? localStorage.getItem(this.openName) : '1';
  },
  methods: {
    getMsgFormMenu(data) {
      this.title_seclectd = data;
      this.open = [data.split('-')[0]];
      localStorage.setItem(this.menuName, data);
      localStorage.setItem(this.openName, this.open);
    },
  },
};
</script>

<style scoped>
.ivu-layout {
  background-color: transparent;
}

.ivu-layout-sider {
  background-color: transparent;
}

.ivu-menu {
  height: 100%;
}
</style>
