<template id="menu">
  <Menu theme="light" :active-name="active_name" :open-names="open_item" @on-select="onSelect" width="auto" accordion>
    <Submenu name="1" v-if="aCheck.moduleCheck('store_manage')">
      <template slot="title">
        <Icon type="ios-list-box"/>
        单位管理
      </template>
      <MenuItem name="1-1" v-if="aCheck.pageCheck('unit_overview')">
        <Icon type="ios-list-box-outline"/>
        单位概况
      </MenuItem>
<!--      <MenuItem name="1-2" v-if="aCheck.pageCheck('address_info')">-->
<!--        <Icon type="ios-menu"/>-->
<!--        地址信息-->
<!--      </MenuItem>-->
      <!-- <MenuItem name="1-3" v-if="aCheck.pageCheck('department_info')">
        <Icon type="md-paw"/>
        部门信息
      </MenuItem> -->
      <MenuItem name="1-4" v-if="aCheck.pageCheck('project_info')">
        <Icon type="ios-albums" />
        项目信息
      </MenuItem>
    </Submenu>

    <Submenu name="2" v-if="aCheck.moduleCheck('warehouse_manage')">
      <template slot="title">
        <Icon type="ios-home"/>
        仓库管理
      </template>
      <MenuItem name="2-3" v-if="aCheck.pageCheck('warehouse_setting')">
        <Icon type="md-grid"/>
        仓库设置
      </MenuItem>
      <!-- <MenuItem name="2-4" v-if="aCheck.pageCheck('area_setting')">
        <Icon type="ios-apps-outline"/>
        库区设置
      </MenuItem>
      <MenuItem name="2-5" v-if="aCheck.pageCheck('location_setting')">
        <Icon type="md-apps"/>
        库位设置
      </MenuItem> -->
    </Submenu>

    <Submenu name="3" v-if="aCheck.moduleCheck('current_unit')">
      <template slot="title">
        <Icon type="ios-people"/>
        往来单位
      </template>
      <MenuItem name="3-6" v-if="aCheck.pageCheck('client_info')">
        <Icon type="ios-contacts"/>
        客户信息
      </MenuItem>
      <MenuItem name="3-7" v-if="aCheck.pageCheck('supplier_info')">
        <Icon type="ios-basket"/>
        供应商信息
      </MenuItem>
      <MenuItem name="3-8" v-if="aCheck.pageCheck('logistics_info')">
        <Icon type="ios-bus"/>
        物流商信息
      </MenuItem>
    </Submenu>

    <Submenu name="4" v-if="aCheck.moduleCheck('financial_setup')">
      <template slot="title">
        <Icon type="logo-usd"/>
        财务设置
      </template>
      <MenuItem name="4-9" v-if="aCheck.pageCheck('flow_category')">
        <Icon type="ios-calculator"/>
        流水类别
      </MenuItem>
    </Submenu>

    <Submenu name="5" v-if="aCheck.moduleCheck('basic_parameters')">
      <template slot="title">
        <Icon type="ios-settings"/>
        基础参数
      </template>
<!--      <MenuItem name="5-10" v-if="aCheck.pageCheck('file_category')">-->
<!--        <Icon type="ios-options"/>-->
<!--        文件类别-->
<!--      </MenuItem>-->
<!--      <MenuItem name="5-13" v-if="aCheck.pageCheck('goods_label')">-->
<!--        <Icon type="ios-bookmark" />-->
<!--        货品标签-->
<!--      </MenuItem>-->
<!--      <MenuItem name="5-14" v-if="aCheck.pageCheck('buy_category')">-->
<!--        <Icon type="ios-cart" />-->
<!--        供应商类别-->
<!--      </MenuItem>-->
<!--      <MenuItem name="5-16" v-if="aCheck.pageCheck('client_category')">-->
<!--        <Icon type="ios-contact" />-->
<!--        客户类别-->
<!--      </MenuItem>-->
      <MenuItem name="5-17" v-if="aCheck.pageCheck('pay_desc')">
        <Icon type="ios-cash" />
        收付款类别
      </MenuItem>
<!--      <MenuItem name="5-18" v-if="aCheck.pageCheck('unit_set')">-->
<!--        <Icon type="md-pricetag" />-->
<!--        单位类别-->
<!--      </MenuItem>-->
      <MenuItem name="5-15" v-if="aCheck.pageCheck('picture_lib')">
        <Icon type="ios-images" />
        图片素材
      </MenuItem>
    </Submenu>

    <Submenu name="6" v-if="aCheck.moduleCheck('account_authority')">
      <template slot="title">
        <Icon type="ios-contacts"/>
        账号权限
      </template>
      <MenuItem name="6-12" v-if="aCheck.pageCheck('role_setting')">
        <Icon type="ios-bicycle"/>
        角色设置
      </MenuItem>
      <MenuItem name="6-11" v-if="aCheck.pageCheck('account_setting')">
        <Icon type="ios-contact"/>
        账号设置
      </MenuItem>
    </Submenu>
  </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: "SettingMenu",
  data() {
    return {
      active_name: this.active,
      open_item: [this.open],
      user_rights: {}
    };
  },
  methods: {
    onSelect(name) {
      this.$emit('func', name);
      loginUtil.setExpireTime();
    }
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },

  props: ["active", 'open'],
};
</script>

<style scoped>
#menu {
  width: 200px;
  position: absolute;
  left: 0;
}
</style>
