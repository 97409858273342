<template>
  <div id="account-setting" class="main-info">
    <div id="panel-header">
      <span class="top-title">账号设置</span>
      <span class="user-limit">剩余可用账号：</span><span class="user-limit-number main-color">{{ left_user_number }}</span>
      <Button type="primary" class="act_create" @click="createOperation('create.item')" v-if="aCheck.rightCheck('edit_account_setting')">
        创建账号
      </Button>
    </div>
    <div class="mt10" v-if="!role_info.length">
      <Alert show-icon>创建账号时，需要给账号分配角色。目前暂无角色配置，小主请先在角色设置页面配置角色信息！</Alert>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="query" :model="query" inline>
          <FormItem prop="phone">
            <i-input @on-blur="searchSubmit" v-model="query.phone" placeholder="请填写手机号" class="w-200"/>
          </FormItem>

<!--          <FormItem prop="department_id">-->
<!--            <Select @on-change="searchSubmit" v-model="query.department_id" placeholder="请选择部门" class="w-200">-->
<!--              <Option v-for="i in departmentList" :value="i.id">{{ i.name }}</Option>-->
<!--            </Select>-->
<!--          </FormItem>-->

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('query')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <!--列表-->
    <div id="list" class="main_list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row, index }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatus(row.status) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="role_name">
          <span v-if="row.role_name">{{ row.role_name }}</span>
          <span v-else class="warning-color">未绑定</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==0" type="text" size="small" class="danger-color"
                  @click="viewItem(row)" v-if="aCheck.rightCheck('valid_account_setting')">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>

          <Button v-show="row.status==1" type="text" size="small" class="warning-color"
                  @click="invalidAction(row)" v-if="aCheck.rightCheck('operate_account_setting')">
            <Icon type="ios-checkmark-circle"/>
            失效
          </Button>

          <Button v-show="row.status==1" type="text" size="small" class="danger-color"
                  @click="resetPasswordAction(row)" v-if="aCheck.rightCheck('operate_account_setting')">
            <Icon type="ios-checkmark-circle"/>
            密码重置
          </Button>

          <Dropdown v-show="row.status==0" v-if="aCheck.rightCheck('edit_account_setting')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==0"><span @click="editAction(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==0"><span @click="deleteAction(row)"
                                                         class="f12">删除</span></DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <span class="fs12 disabled-color"><Icon type="ios-alert-outline"/> 只有顶级账号才有操作账号的权限！！！</span>
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page"/>
      </div>
    </div>

    <!--创建-->
    <Modal v-model="create.modal" :title="create.title" width="800">
      <div class="modalForm">
        <Form ref="create.item" :model="create.item" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="16">
            <Col span="16" offset="4">
              <FormItem label="用户名" prop="name">
                <Input v-model="create.item.name" placeholder="请填写用户名"></Input>
              </FormItem>
              <FormItem label="手机号" prop="phone">
                <Input v-model="create.item.phone" type="number" placeholder="请填写手机号"
                       maxlength="11"></Input>
              </FormItem>
              <FormItem label="角色绑定" prop="role_id">
                <Select v-model="create.item.role_id" placeholder="请选择角色">
                  <Option v-for="i in role_info" :value="i.id">{{ i.name }}</Option>
                </Select>
              </FormItem>
<!--              <FormItem label="部门选择" prop="department_id">-->
<!--                <Select v-model="create.item.department_id" placeholder="请选择部门">-->
<!--                  <Option value="0">暂无</Option>-->
<!--                  <Option v-for="i in departmentList" :value="i.id">{{ i.name }}</Option>-->
<!--                </Select>-->
<!--              </FormItem>-->
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancelCreateOperation('create.item')">关闭</Button>
        <Button type="primary" :loading="create.modal_loading" @click="createPost('create.item')">保存
        </Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal v-model="view.modal" :title="view.title" width="800">
      <div class="modalForm">
        <Form :model="view.item" :label-width="100" label-colon>
          <Row :gutter="16">
            <Col span="16" offset="4">
              <FormItem label="用户名">
                <span>{{ view.item.name }}</span>
              </FormItem>
              <FormItem label="手机号">
                <span>{{ view.item.phone }}</span>
              </FormItem>
              <FormItem label="角色绑定">
                <span v-for="i in role_info" v-if="i.id==view.item.role_id">{{ i.name }}</span>
              </FormItem>
<!--              <FormItem label="所在部门">-->
<!--                <span v-for="i in departmentList" v-if="i.id==view.item.department_id">{{ i.name }}</span>-->
<!--              </FormItem>-->
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetViewItem(view)">取消</Button>
        <Button v-show="view.item.status==0" type="primary" @click="editAction(view.item)">修改</Button>
        <Button v-show="view.item.status==0" type="success" :loading="view.modal_loading"
                @click="validAction(view.item)">生效
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as department_util from '@/utils/department';

import {
  getAccountSettingList,
  createUser,
  validStatus,
  invalidUser,
  deleteUser,
  getRoleList,
  getLeftUserNumber,
  resetPasswordUser
} from '@/api/setting/accountSetting'

export default {
  name: "AccountSetting",
  data() {
    return {
      query: {
        phone: '',
        department_id: 0,
      },
      list: {
        columns: [
          {
            title: '用户名',
            key: 'name',
            align: 'center'
          },
          {
            title: '手机号',
            key: 'phone',
            align: 'center'
          },
          {
            title: '角色',
            key: 'role_name',
            slot: 'role_name',
            align: 'center'
          },
          // {
          //   title: '部门',
          //   key: 'department_name',
          //   align: 'center'
          // },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center'
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            minWidth: 60,
          }
        ],
        data: [],
        page: 1,
        total: 0,
        size: 0,
      },
      create: {
        title: '账号信息',
        modal: false,
        modal_loading: false,
        item: {
          id: '',
          name: '',
          phone: '',
          role_id: '',
          department_id: 0,
        }
      },
      view: {
        title: '账号信息',
        modal: false,
        modal_loading: false,
        item: {
          id: '',
          name: '',
          phone: '',
        }
      },
      ruleInline: {
        name: [
          {required: true, message: '请填写'},
        ],
        phone: [
          {required: true, message: '请填写手机号'},
        ],
        role_id: [
          {required: true, message: '请选择角色'},
        ],
      },
      role_info: [],
      left_user_number: 0,
      // departmentList: [],
    }
  },
  methods: {
    // getDepartmentList() {
    //   department_util.getDepartmentAll().then(res => {
    //     this.departmentList = res;
    //   });
    // },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    deleteAction(row) {
      this.$Modal.confirm({
        title: "账号删除",
        content: '确定要将该账号删除？删除后，用户将无法使用该账号登录系统，该账号将不会在列表中呈现',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          deleteUser({id: row.id}).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    resetPasswordAction(item) {
      this.$Modal.confirm({
        title: "账号密码重置",
        content: '确定要重置该账号密码？重置后，用户将无法使用旧密码登录，请知晓！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          resetPasswordUser({id: item.id}).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    invalidAction(row) {
      this.$Modal.confirm({
        title: "账号失效",
        content: '确定要将该账号失效？失效后，用户将无法使用该账号登录系统',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          invalidUser({id: row.id}).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    validAction() {
      this.view.modal_loading = true;
      validStatus(this.view.item).then((res) => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getMainList();
          this.view.modal = false;
        } else {
          this.$Message.error(res.data.err_msg);
          this.view.modal_loading = false;
        }
      }).catch((res) => {
        this.view.modal_loading = false;
      });
    },
    editAction(row) {
      this.view.modal = false;
      this.create.item.id = row.id;
      this.create.item.name = row.name;
      this.create.item.phone = row.phone;
      this.create.item.role_id = row.role_id;
      this.create.item.department_id = row.department_id;
      this.create.modal = true;
      this.create.modal_loading = false;
    },
    handleResetViewItem(item) {
      item.modal = false;
    },
    viewItem(row) {
      this.view.modal = true;
      this.view.modal_loading = false;
      this.view.item = row;
    },
    createPost(item) {
      this.create.modal_loading = true;

      if (this.left_user_number <= 0) {
        this.create.modal_loading = false;
        this.$Message.success('剩余用户数量不足，暂不可创建用户，请联系客服升级账号');
        return false;
      }

      this.$refs[item].validate((valid) => {
        if (valid) {
          createUser(this.create.item).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
              this.create.modal = false;
            } else {
              this.$Message.error(res.data.err_msg);
              this.create.modal_loading = false;
            }
          }).catch((e) => {
            this.create.modal_loading = false;
          });
        } else {
          this.create.modal_loading = false;
        }
      });
    },
    cancelCreateOperation(item) {
      this.$refs[item].resetFields();
      this.view.modal = false;
      this.create.modal = false;
    },
    createOperation(item) {
      this.$refs[item].resetFields();
      this.create.modal_loading = false;
      this.create.modal = true;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        department_id: this.query.department_id,
        phone: this.query.phone,
      };

      getAccountSettingList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;

        this.getLeftUserNumber();
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    getStatus(status) {
      let name = '';
      switch (status) {
        case 0:
          name = '待生效';
          break;
        case 1:
          name = '有效';
          break;
      }
      return name;
    },
    getStatusColor(status) {
      let name = '';
      switch (status) {
        case 0:
          name = 'color:#ed4014';
          break;
        case 1:
          name = 'color:#2775ff';
          break;
      }
      return name;
    },
    getUserRoleList() {
      getRoleList().then((res) => {
        this.role_info = res.data.results;
      });
    },
    getLeftUserNumber() {//获取剩余可用账号数
      getLeftUserNumber().then((res) => {
        this.left_user_number = res.data.results;
      });
    }
  },
  created() {
    this.aCheck.plateName = 'common_setting';// 权限设置 模块名称
  },
  mounted() {
    this.getMainList();
    this.getUserRoleList();

    // this.getDepartmentList();
  }
}
</script>

<style scoped>
#account-setting {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.act_create {
  position: absolute;
  right: 0;
  top: -8px;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#account-setting .ivu-card {
  background-color: #fafafa;
}

#account-setting .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#account-setting .ivu-form-item {
  margin-bottom: 0;
}

#account-setting .ivu-btn-small {
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.user-limit {
  color: #333;
  cursor: pointer;
  font-weight: 300;
  font-size: 12px;
  margin-left: 40px;
}

.user-limit-number {
  font-weight: 600;
  font-size: 14px;
}
</style>
<style>
#account-setting .ivu-card {
  background-color: #fafafa;
}

.main_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.main_list .ivu-table-wrapper {
  overflow: visible;
}

#account-setting .ivu-alert {
  font-size: 12px;
  line-height: 14px;
}

#account-setting .ivu-alert-info {
  border: 0;
}
</style>
