import request from '@/plugins/http'

export function apiGetClientsAll() {
    return request.post('/api/transitunit/getClientsAll');
}

export function apiAddClientFast(param) {
    return request.post('/api/transitunit/addClientFast', param);
}


